import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'


//TODO to servicecontent na min einai hardcoded

class Services extends React.Component {
  constructor(props) {

  super(props);

}
  componentDidMount() {
    // var $= window.$
    // var itemheight = 0;
    // $('.item-content').each(function(index) {
    //   if($(this).height() > itemheight) {
    //     itemheight = $(this).height()
    //   }
    // })
    // console.log(itemheight)
    // // $('.item-content').height(itemheight)
    // $('.item-content').css('min-height', itemheight + 10);
    //
    //
    // $('.owl-carousel').each(function() {
    //   var $carousel = $(this);
    //   $carousel.find('> *').each(function (i) {
    //     $(this).attr('data-index', i);
    //   });
    //   var data = $carousel.data();
    //
    //   var loop =  true,
    //     margin = (data.margin || data.margin === 0) ? data.margin : 30,
    //     nav = data.nav ? data.nav : false,
    //     navPrev = data.navPrev ? data.navPrev : '<i class="fa fa-angle-left">',
    //     navNext = data.navNext ? data.navNext : '<i class="fa fa-angle-right">',
    //     dots = data.dots ? data.dots : false,
    //     themeClass = data.themeclass ? data.themeclass : 'owl-theme',
    //     center = data.center ? data.center : false,
    //     items = data.items ? data.items : 4,
    //     autoplay = data.autoplay ? data.autoplay : false,
    //     responsiveXs = data.responsiveXs ? data.responsiveXs : 1,
    //     responsiveSm = data.responsiveSm ? data.responsiveSm : 2,
    //     responsiveMd = data.responsiveMd ? data.responsiveMd : 3,
    //     responsiveLg = data.responsiveLg ? data.responsiveLg : 4,
    //     draggable = (data.draggable === false) ? data.draggable : true,
    //     syncedClass = (data.syncedClass) ? data.syncedClass : false,
    //     filters = data.filters ? data.filters : false;
    //
    //   if (filters) {
    //     $carousel.after($carousel.clone().addClass('owl-carousel-filter-cloned'));
    //     $(filters).on('click', 'a', function( e ) {
    //       //processing filter link
    //       e.preventDefault();
    //       if ($(this).hasClass('selected')) {
    //         return;
    //       }
    //       var filterValue = $( this ).attr('data-filter');
    //       $(this).siblings().removeClass('selected active');
    //       $(this).addClass('selected active');
    //
    //       //removing old items
    //       for (var i = $carousel.find('.owl-item').length - 1; i >= 0; i--) {
    //         $carousel.trigger('remove.owl.carousel', [1]);
    //       };
    //
    //       //adding new items
    //       var $filteredItems = $($carousel.next().find(' > ' +filterValue).clone());
    //       $filteredItems.each(function() {
    //         $carousel.trigger('add.owl.carousel', $(this));
    //         $(this).addClass('scaleAppear');
    //       });
    //
    //       $carousel.trigger('refresh.owl.carousel');
    //
    //       //reinit prettyPhoto in filtered OWL carousel
    //       if ($().prettyPhoto) {
    //         $carousel.find("a[data-gal^='prettyPhoto']").prettyPhoto({
    //           hook: 'data-gal',
    //           theme: 'facebook' /* light_rounded / dark_rounded / light_square / dark_square / facebook / pp_default*/
    //         });
    //       }
    //     });
    //
    //   } //filters
    //
    //   $carousel.owlCarousel({
    //     loop: loop,
    //     margin: margin,
    //     nav: nav,
    //     autoplay: autoplay,
    //     dots: dots,
    //     themeClass: themeClass,
    //     center: center,
    //     navText: [navPrev,navNext],
    //     mouseDrag: draggable,
    //     touchDrag: draggable,
    //     items: items,
    //     responsive: {
    //       0:{
    //         items: responsiveXs
    //       },
    //       767:{
    //         items: responsiveSm
    //       },
    //       992:{
    //         items: responsiveMd
    //       },
    //       1200:{
    //         items: responsiveLg
    //       }
    //     },
    //   })
    //   .addClass(themeClass);
    //   if(center) {
    //     $carousel.addClass('owl-center');
    //   }
    //
    //
    //
    //   //topline two synced carousels
    //   if($carousel.hasClass('owl-news-slider-items') && syncedClass) {
    //     $carousel.on('changed.owl.carousel', function(e) {
    //       var indexTo = loop ? e.item.index+1 : e.item.index;
    //       $(syncedClass).trigger('to.owl.carousel', [indexTo]);
    //     })
    //   }
    //
    //
    // });

  }
  componentWillUnmount(){

  }


  render() {


    return (

       <div>



         <section className="ls s-pt-30 s-pb-20 s-pb-lg-50 s-pt-lg-50 c-gutter-60 c-mb-40 c-mb-md-60 service-item2">
           <div className="d-none d-lg-block divider-65"/>
           <div className="container">
             <div className="row">

               {this.props.data.drupal.nodeQuery.entities.map((item, i) => {
                 return (
                   <div key={i} className="col-md-4 col-sm-6">

                     <div className="vertical-item text-center">
                       <div className="item-media">
                         {item.fieldImageIcon ? <img src={item.fieldImageIcon.imageFile.publicURL} alt={item.fieldImageIcon.alt}/> : <img src='https://loremflickr.com/200/200' alt=""/>}

                       </div>
                       <div className="item-content">
                         <h6>
                           <Link to={item.entityUrl.path+'/'}><span>{item.title}</span></Link>
                         </h6>

                         <p>
                           {item.body.summary}
                         </p>

                       </div>
                     </div>
  {/*                   <div className="relatedworks mt-4">

                                <h6 className="text-center">Related Works</h6>
                                  <section className=" gallery-carousel main-gallery container-px-0" id="gallery">
                                    <div className="container-fluid">

                                      <div className="row">
                                        <div className="col-lg-12">

                                          <div className="owl-carousel gallery-owl-nav" data-autoplay="false" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1" data-nav="true" data-dots="false" data-filters=".gallery-filters" data-margin="0" data-loop="true">

                                            {item.fieldCategories[0].entity.reverseFieldCategoriesNode.entities.map((node,i)=> {

                                              return (



                                                  <div className={`vertical-item item-gallery mr-2 content-absolute text-center ds `}>
                                                    <div className="containertv">
                                                      <div className="containerscreen">
                                                    <div className="item-media item-media-custom2 item-media-custom3">
                                                     <Link to={node.entityUrl.path}><img  src={node.fieldDesktopImage[0].url} alt=""/></Link>

                                                    </div>
                                                    </div>
                                                     </div>
                                                  </div>


                                              )
                                            })}


                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </section>


                     </div> */}
                   </div>
                 )
               })}










               <div className="d-none d-lg-block divider-10"></div>
             </div>
           </div>
         </section>


       </div>











    )
  }
}



export default Services;
