import React from 'react'
import Canvas from '../layouts/canvas'
import Header from '../layouts/header'
import Footer from '../layouts/footer'
import {graphql} from 'gatsby'
import Services from '../layouts/servicescontent'
import Breadcrumb from '../layouts/breadcrumb'
import {Helmet} from 'react-helmet'


class Mainservices extends React.Component{
  constructor(props) {
    super(props)
  }

  render() {
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen
    }
    else {
      menu = this.props.pageContext.menuel
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({url:item.entityUrl.path, id:item.entityLanguage.id})
    })
    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })

       return (
         <div>
           <Helmet>
            {this.props.pageContext.metainfo.map((item,i) => {
              if(item.key === 'title') {
                return (
                [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
                )
              }

              else if(item.key === 'description') {
                return (
                [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
                )
              }

              else {
                return null;
              }
            })}
            <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />
            <meta property="og:type" content="Website" />
            <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
            <meta property="og:image" content={`${process.env.FRONT_URL}/wd_logo.png`} />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
           </Helmet>

          <Canvas>
        <Header language={this.props.pageContext.langcode} menu={menu} langpath={langpath} pathname={this.props.path}/>
        <Breadcrumb title={this.props.pageContext.title} path={loc}/>
          <Services data={this.props.data}/>

          <Footer/>

         </Canvas>
        </div>
       )
  }






}

export default Mainservices;


export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
nodeQuery(filter: {conditions: [{field: "type", value: "service", language: $langcode},{field: "status", value: "1"}]}) {
 entities(language: $langcode) {
   ... on DRUPAL_NodeService {
     title
     body {
       summary
     }
     entityUrl {
      path
    }
     fieldImageIcon {
      url
      alt
      imageFile{
        publicURL
      }
    }

   }
 }
}
}
}
`
